@import "~ng-zorro-antd/ng-zorro-antd.less";

// Timeline
// ---
@timeline-width: 2px;
@timeline-color: #4394e6;
@timeline-dot-border-width: 2px;
@timeline-dot-color: @primary-color;
@timeline-dot-bg: #3f3f3f;
@timeline-item-padding-bottom: 15px;

/* width */
::-webkit-scrollbar {
  width: 15px;
  background-color: #3f3f3f; 
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3f3f3f; 
  border-radius: 6px;
  background-color: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3f3f; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666464; 
}

@font-face {
  font-family: oswald;
  src: url(./assets/fonts/Oswald-Regular.ttf);
}

@font-face {
  font-family: 'open-sans';
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}

.ant-card-cover {
  img {
    padding: 30px;
  }
  margin-top: -2px !important;
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.ant-card-body {
  font-family: 'open-sans';
  
  .ant-card-meta-title {
    font-weight: 600;
    font-size: 18px;
  }

  .ant-card-meta-description {
    font-weight: 600;
    font-size: 16px;
  }
}